import { styled } from 'linaria/react';
import React from 'react';
import { Container } from 'react-bootstrap';

const Styled = {
  FlexContainer: styled(Container)`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `
};

/**
 * This allows any child component to create a 100% height container using
 * flex-grow: 1 and not incur any odd scrolling / margin-collapsing issues.
 */
export const FlexContainer: React.FC<React.PropsWithChildren<any>> = ({children}) => (
  <Styled.FlexContainer>
    {children}
  </Styled.FlexContainer>
);
