import * as openpgp from 'openpgp';
import { UAParser } from 'ua-parser-js';

import { BOOTSTRAP_BREAKPOINTS } from 'etc/constants';


/**
 * Converts the provided Buffer or Uint8Array to a hexadecimal string.
 */
export function buf2hex(buffer: any) {
  return Array.prototype.map.call(new Uint8Array(buffer), x => `00${x.toString(16)}`.slice(-2)).join('');
}


/**
 * Provided a Key, returns its type using an instanceof check.
 */
export function getKeyType(key: openpgp.key.Key) {
  if (key instanceof openpgp.packet.SecretKey) {
    return 'private';
  }

  if (key instanceof openpgp.packet.PublicKey) {
    return 'public';
  }

  return 'unknown';
}


/**
 * Bootstrap media query helper for CSS-in-JS.
 *
 * @example
 *
 * @media ${bp('sm')} {
 *   // ...
 * }
 */
export function bp(bpName: keyof typeof BOOTSTRAP_BREAKPOINTS, minMax: 'min' | 'max' = 'min') {
  const bpValue = BOOTSTRAP_BREAKPOINTS[bpName];

  if (bpValue === undefined) {
    throw new Error(`Invalid breakpoint: ${bpName}`);
  }

  const value = minMax === 'min' ? bpValue[0] : bpValue[1];

  return `(${minMax}-width: ${value}px)`;
}


/**
 * Returns true if the current device is a mobile device.
 */
export function isMobileDevice() {
  const results = new UAParser();
  const type =  results.getDevice().type;
  return type && ['mobile', 'tablet', 'wearable', 'embedded'].includes(type);
}
