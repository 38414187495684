import { styled } from 'linaria/react';
import React from 'react';

import { FlexContainer } from 'components/FlexContainer';

const Styled = {
  NotFound: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding-bottom: 64px;

    & img {
      margin-bottom: 64px;
      max-width: 100%;
      opacity: 0.24;
    }
  `
};

export const NotFound = () => {
  return (
    <FlexContainer>
      <Styled.NotFound>
        <div className="text-secondary">
          Not found.
        </div>
      </Styled.NotFound>
    </FlexContainer>
  );
};
