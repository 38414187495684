import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import MobileNavbar from 'components/MobileNavbar';
import { Navbar } from 'components/Navbar';
import { SuspenseFallback } from 'components/SuspenseFallback';
import { isMobileDevice } from 'lib/utils';
import { routes } from 'routes';
import { NotFound } from 'routes/not-found/NotFound';

// ----- App Layout ------------------------------------------------------------

export const App = hot(() => {
  return (
    <React.StrictMode>
      <Router>
        {!isMobileDevice() && <Navbar />}
        <div id="content">
          <React.Suspense fallback={<SuspenseFallback />}>
            <Switch>
              {routes.map(routeConfig => (
                <Route
                  key={routeConfig.name}
                  path={routeConfig.pathname}
                  exact={routeConfig.exact}
                >
                  {routeConfig.component}
                </Route>
              ))}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </React.Suspense>
        </div>
        {isMobileDevice() && <MobileNavbar />}
      </Router>
    </React.StrictMode>
  );
});
