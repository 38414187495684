// import sleep from '@darkobits/sleep';
import React from 'react';
import { BsHouse, BsSearch, BsReverseLayoutTextSidebarReverse } from 'react-icons/bs';

import { RouteMap } from 'lib/route-map';


const Home = React.lazy(async () => import('routes/home/Home'));
const Analyze = React.lazy(async () => {
  // await sleep(5000000);
  return import('routes/analyze/Analyze');
});
const Contacts = React.lazy(async () => {
  // await sleep(5000000);
  return import('routes/contacts/Contacts');
});

export const routes = new RouteMap({
  name: 'home',
  label: String(process.env.NAME),
  pathname: '/',
  exact: true,
  component: <Home />,
  icon: <BsHouse />
}, {
  name: 'contacts',
  label: 'Contacts',
  pathname: '/contacts',
  component: <Contacts />,
  icon: <BsReverseLayoutTextSidebarReverse />
}, {
  name: 'analyze',
  label: 'Analyze',
  pathname: '/analyze',
  component: <Analyze />,
  icon: <BsSearch />
});
